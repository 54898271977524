import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { Movie } from '@usheru-hff/shared/domain';
import { inject } from '@angular/core';
import { MovieService } from '@usheru-hff/shared/utils-movie';
import { BrandMovieService } from './brand-movie.service';
import { of, tap } from 'rxjs';

/**
 * Used to load the movie data before the component is rendered.
 * If a routerLink has a movie object in the state, it will use
 * that object instead of fetching it from the API. The API call
 * will be delayed and it is done to get the full movie data.
 */

export const brandAwarenessMovieResolver: ResolveFn<Movie | null> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const movieService = inject(MovieService);
  const brandMovieService = inject(BrandMovieService);
  const router = inject(Router);
  const slug = route.data['slug'] || '';
  const navigation = router.getCurrentNavigation();
  const navState = navigation?.extras?.state;
  const movie: Movie = navState ? navState['movie'] : null;

  // if (navState && navState['skip']) {
  //   return of(movie);
  // }

  const setMovie = (movie: Movie, navigation = true) => {
    brandMovieService.setMovie(movie);

    if (navigation) {
      const isAwereness = brandMovieService.isMovieAwareness(movie);
      const navigationUrl = isAwereness ? 'film-info' : 'book-now';
      router.navigate([state.url.split('?')[0], navigationUrl], {
        state: {
          skip: true
        }
      });
    }
  };

  const requestMovie = (navigation = true) =>
    movieService.getMovie(slug).pipe(
      tap(movie => {
        setMovie(movie, navigation);
      })
    );

  try {
    if (movie) {
      brandMovieService.setMovie(movie);
      return of(movie);
    }
    return requestMovie();
  } catch {
    router.navigate(['/not-found']);
    return of(null);
  }
};
