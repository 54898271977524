import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Movie } from '@usheru-hff/shared/domain';

import { from, Observable, of, switchMap } from 'rxjs';
/**
 * !NOTE: if this movies are to display as branded pages, remember add to the app.component.ts
 */
const placeholderMoviesList = ['lee-2', 'something-in-the-water', 'the-outrun', 'woodwalkers'];

/**
 * !USAGE
 * Import the providePlacehoderMovieInterceptor in the app.module before the provideCacheInterceptor
 */

@Injectable()
export class MoviePlaceholderInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.method == 'GET' && this.endsWithInList(req.url)) {
      const movieReq = getHardcodedMovie(req.url.split('/').pop()!);
      if (!movieReq) return next.handle(req);
      return from(movieReq).pipe(
        switchMap(movie => {
          return of(createHttpResponse(movie));
        })
      );
    }

    return next.handle(req);
  }

  endsWithInList(url: string): boolean {
    return placeholderMoviesList.some(movie => url.endsWith(`movies/${movie}`));
  }
}

export function providePlacehoderMovieInterceptor(): Provider {
  return {
    provide: HTTP_INTERCEPTORS,
    useClass: MoviePlaceholderInterceptor,
    multi: true
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createHttpResponse(body: any): HttpResponse<any> {
  return new HttpResponse<Movie>({
    status: 200,
    statusText: 'OK',
    body
  });
}

function getHardcodedMovie(slug: string): Promise<Movie> | null {
  return import(`./hardcoded-movies/${slug}.json`) as Promise<never> as Promise<Movie>;
}
