import { Route } from '@angular/router';
import { brandAwarenessMovieResolver } from './brand-awareness-conversion.resolver';
import { MovieResolver } from './movie.resolver';

export const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    resolve: { movie: brandAwarenessMovieResolver },
    loadComponent: () =>
      import('./movie-detail-feature-movie-awareness-page/feature-movie-awareness-page.component').then(
        m => m.FeatureMovieAwarenessPageComponent
      )
  },
  {
    path: 'film-info',
    pathMatch: 'full',
    resolve: { movie: MovieResolver },
    loadComponent: () =>
      import('./movie-detail-feature-movie-awareness-page/feature-movie-awareness-page.component').then(
        m => m.FeatureMovieAwarenessPageComponent
      )
  },
  {
    path: 'book-now',
    pathMatch: 'full',
    resolve: { movie: MovieResolver },
    loadComponent: () =>
      import('./feature-movie-conversion-page/feature-movie-conversion-page.component').then(
        m => m.FeatureMovieConversionPageComponent
      )
  }
];
