import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Movie } from '@usheru-hff/shared/domain';
import { inject } from '@angular/core';
import { MovieService } from '@usheru-hff/shared/utils-movie';
import { BrandMovieService } from './brand-movie.service';
import { Observable, of, tap } from 'rxjs';

export const MovieResolver: ResolveFn<Movie | null> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const movieService = inject(MovieService);
  const brandMovieService = inject(BrandMovieService);
  const slug = route.data['slug'];
  try {
    return movieService.getMovie(slug).pipe(tap(movie => brandMovieService.setMovie(movie)));
  } catch {
    return of(null);
  }
};
